.bg-full {
  top: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 0;
}
.error-span {
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}
.input-group-text.disabled {
  background: #e9ecef !important;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.rounded-nav-link {
  border-radius: 10px;
  margin: 5px;
  box-shadow: 0 0 0.5rem rgba(136, 152, 170, 0.075) !important;
}
.rounded-nav-link.active {
  background: linear-gradient(0deg, #ff5858 0, #f09819 100%) !important;
}
.rounded-nav-link.active > a {
  color: #e9ecef !important;
}
.normal-wrap {
  white-space: normal !important;
}
.break-me {
  margin: 0 10px;
  font-size: 25px;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 6px solid #ed213a;
  border-radius: 50%;
  animation: lds-ring 1.1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #ed213a transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.example-enter {
  transition: opacity 500ms ease-in-out;
  opacity: 0;
}
.example-enter.example-enter-active {
  opacity: 1;
}
.example-leave {
  transition: opacity 500ms ease-in-out;
  opacity: 0;
}
.example-leave.example-leave-active {
  opacity: 0;
}
.form-select {
  padding: 0.25rem 0.75rem;
  height: auto;
}
.form-select > .css-vj8t7z {
  border: none;
  background-color: transparent;
}
.has-danger[type="date"]:after {
  margin-right: 40px;
}
.has-danger.css-10nd86i:after {
  margin-right: 40px;
}
